import { ToastContainer } from "react-toastify"
import SomenteLogoBranca from "../../images/SomenteLogoBranca.png";

export const VbNotification = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={3000}
            limit={3}
            closeOnClick
            theme="colored"
            style={{ fontSize: '14px' }}
            icon={<img src={SomenteLogoBranca} width={'24px'} alt='' />}
        />
    )
}