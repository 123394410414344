import styled from "styled-components";
import { isMobileDevice } from "../../commons/utils";
import { Col, Container, Form, Row } from "react-bootstrap";
import { VbInput } from "../../components/VbInput";
import LogoMedia from "../../images/LogoMedia.png"
import { VbButton } from "../../components/VbButton";
import { VbRadioButton } from "../../components/VbRadioButton";
import { COLOR_PRIMARY } from "../../theme/styles";
import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";

const ContainerMain = styled.div`
    display: flex;
    min-height: 100vh;
    background: linear-gradient(180deg, #f1692a 0%, #b05f3a 100%);
`;

const FormContainer = styled(Container)`
    max-width: ${isMobileDevice() ? '100%' : '700px'};
    min-height: 800px;
    height: 100%;
    padding: ${isMobileDevice() ? '1.5rem' : '2rem 4rem 0rem 4rem'};
    margin-top: ${isMobileDevice() ? '0px' : '50px'};
    background-color: #fcf3ef;
    border-radius: ${isMobileDevice() ? '0px' : '7px'};
    box-shadow: 4px 0px 15px rgba(245, 245, 245, 0.3), 4px 0px 6px rgba(255, 255, 255, 0.2);
`;

const Logo = styled.img`
    width: 100%;
    padding: 20px 50px 0px;
`;

const Title = styled.h5``
const Description = styled.h6``;

export const SAC = () => {
    const auth = useAuth();

    const descriptionRef = useRef<any>();

    const [tipo, setTipo] = useState('sugestão');
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState<any>(false);

    async function sendSAC() {
        let _data: any = {
            sacid: 0,
            sactipo: tipo,
            sacdescricao: descriptionRef.current.getValue()
        };

        setDisabled(true);

        await auth.post('/sac', _data)
            .then(() => {
                toast.success('Opinião enviada com sucesso.\nObrigado por utilizar o SAC VbFit.');
                descriptionRef.current.setValue('');
            })
            .catch((error: any) => {
                console.log(error);
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
            })
            .finally(() => {
                setDisabled(false);
            })
    }

    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            sendSAC()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <ContainerMain>
            <FormContainer className="d-flex flex-column">
                <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                    <fieldset disabled={disabled}>
                        <Row className="mb-5 justify-content-center">
                            <Logo src={LogoMedia} />
                        </Row>
                        <Row className="mb-2">
                            <Title>Queremos <b style={{ color: COLOR_PRIMARY }}>sua opinião!</b></Title>
                        </Row>
                        <Row className="mb-4">
                            <Description>Para evoluirmos ainda mais, responda com sinceridade, sem ressentimentos!</Description>
                        </Row>
                        <Row className="mb-2">
                            <Description>*Selecione o que quer compartilhar com a gente:</Description>
                        </Row>
                        <Row className="mb-4 justify-content-center">
                            <Col className="justify-content-center">
                                <VbRadioButton id="sugestão" label="Sugestão" defaultChecked name="group1" onClick={(e: any) => setTipo(e.target.id)} />
                            </Col>
                            <Col className="justify-content-center">
                                <VbRadioButton id="reclamação" label="Reclamação" name="group1" onClick={(e: any) => setTipo(e.target.id)} />
                            </Col>
                            <Col className="justify-content-center">
                                <VbRadioButton id="elogio" label="Elogio" name="group1" onClick={(e: any) => setTipo(e.target.id)} />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Description>*Descreva abaixo {tipo === 'elogio' ? 'seu' : 'sua'} <b style={{ color: COLOR_PRIMARY }}>{tipo}</b> para nós:</Description>
                        </Row>
                        <Row className="mb-2 justify-content-center">
                            <VbInput ref={descriptionRef} id="descricao" name="descricao" type="text" textarea required rows={5} maxLength={1500} />
                        </Row>
                        <Row className="m-0 mt-4 mb-4 justify-content-center">
                            <VbButton type="submit" style={{ width: '100%' }}>Enviar</VbButton>
                        </Row>
                    </fieldset>
                </Form>
            </FormContainer>
        </ContainerMain >
    )
}