import { Api } from "../../api/address";

export async function GetRequest(url: string) {
    try {
        const response = await Api.get(url);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function PostRequest(url: string, data: any) {
    const config = {
        headers: { 'Content-Type': 'application/json' }
    }

    try {
        const response = await Api.post(url, data, config);
        return response;
    } catch (error: any) {
        return error
    }
}
