import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';
import { lighten } from 'polished';
import { COLOR_PRIMARY } from '../../theme/styles';

const Icon = styled.div`
    display: flex;
    margin-right: 5px;
    pointer-events: none;
    background-color: transparent;
`

interface VbButtonProps extends ButtonProps {
    icon?: any;
}

export const VbButton = (props: VbButtonProps) => {
    return (
        <Button
            size={props.size ? props.size : 'sm'}
            {...props}
            style={{
                ...props.style,
                backgroundColor: COLOR_PRIMARY,
                borderColor: COLOR_PRIMARY,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onFocus={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.05rem ${COLOR_PRIMARY}80, 0 0 0 0.1rem ${COLOR_PRIMARY}40`;
            }}
            onBlur={(e: any) => {
                e.target.style.boxShadow = 'none';
            }}
            onMouseDown={(e: any) => {
                e.target.style.backgroundColor = lighten(0.1, COLOR_PRIMARY);
                e.target.style.borderColor = lighten(0.1, COLOR_PRIMARY);
            }}
            onMouseUp={(e: any) => {
                e.target.style.backgroundColor = COLOR_PRIMARY;
                e.target.style.borderColor = COLOR_PRIMARY;
            }}
        >
            {props.icon &&
                <Icon>
                    {props.icon}
                </Icon>
            }
            {props.children}
        </Button>
    )
}
