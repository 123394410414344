import { VbNotification } from "./components/VbNotification";
import { SAC } from "./pages/SAC";

function App() {
  return (
    <div>
      <SAC />
      <VbNotification />
    </div>
  );
}

export default App;
