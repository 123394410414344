import { createContext } from 'react';
import { IAuthProvider, IContext } from './types';
import { GetRequest, PostRequest } from './util';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    async function get(url: string) {
        const response = await GetRequest(url);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function post(url: string, data: any) {
        const response = await PostRequest(url, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    return (
        <AuthContext.Provider value={{ get, post }}>
            {children}
        </AuthContext.Provider>
    )
}