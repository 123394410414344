import axios from 'axios';

const currentUrl = window.location.href;

export const Api = axios.create({
    baseURL: (
        currentUrl.includes('localhost') ||
        currentUrl.includes('doarevida-hom.vbfit.com.br') ||
        currentUrl.includes('127.0.0.1') ||
        currentUrl.includes('25.1.177.182') ||
        currentUrl.includes('192.168.0.107')) ?
        'https://api-hom.vbfit.com.br/' : 'https://api.vbfit.com.br/'
})
