import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { VbInput } from "../../components/VbInput";

export const VbFormLabel = styled(Form.Label)`
    margin-left: .375rem;
    margin-bottom: .275rem;  
    font-size: .785rem;
    font-weight: bold;
`

export const VbFormFeedback = styled(Form.Control.Feedback)`
    margin: 0;
    font-size: .785rem;
    font-weight: 500;
`
interface VbControlsProps {
    id: any;
    name: any;
    type: any;
    label?: String;
    placeholder?: any;
    defaultValue?: any;
    feedback?: String;
    textfixed?: String;
    required?: any;
    md?: String;
    size?: any;
    onAfterChange?: any;
    search?: any;
    disabled?: any;
    options?: any;
    model?: any;
    validated?: any;
}

export const VbControls = forwardRef((props: VbControlsProps, ref: any) => {
    const { options, ...input } = props;

    return (
        <VbInput ref={ref} {...input} />
    )
})