import { FormCheckProps } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './styles.css';
import { forwardRef } from 'react';

export const VbRadioButton = forwardRef((props: FormCheckProps, ref: any) => {
    return (
        <Form.Check
            {...props}
            type={'radio'}
            style={{ fontWeight: '500', display: 'flex', justifyContent: 'center' }}
        />
    )
})
